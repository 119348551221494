import * as React from 'react'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { Link as RouterLink, GatsbyLinkProps, navigate } from 'gatsby'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { styled } from '@material-ui/styles'
import Measure from 'react-measure'
import { useStaticQuery, graphql } from 'gatsby'
import WebMercatorViewport from 'viewport-mercator-project'
import { FastField, Field, FieldArray, Formik } from 'formik'
import { path } from 'ramda'

import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import Image from 'material-ui-image';

import MapGL, { Marker } from '@urbica/react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import {
  createStyles,
  withStyles,
  Theme,
  WithStyles
} from '@material-ui/core/styles'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
// import { Wrapper } from '../../components/wrapper';

const BACKGROUND_COLOR = '#F5F7FA'
const BORDER_COLOR = '#E4E7EB'

const HR = styled('div')({
  height: '1px',
  width: '100%',
  backgroundColor: BORDER_COLOR
})

const Wrapper = styled(Box)({
  backgroundColor: BACKGROUND_COLOR
})

const LinkComponent = React.forwardRef<
  HTMLAnchorElement,
  Omit<GatsbyLinkProps<{}>, 'ref'>
>((props, ref) => (
  // @ts-ignore
  <RouterLink innerRef={ref} {...props} />
))

const LOCATIONS = [[-79.3951, 43.64466], [-79.389549, 43.645552]]

const initialBounds = {
  latitude: 43.64466,
  longitude: -79.3951,
  zoom: 15
}

const MapMarker = require('../../assets/map-marker.svg')

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

const MapWrapper = withStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(30)
    }
  }
}))(Box)

const StyledPaper = withStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5)
    } 
  }
}))(Paper)

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          primaryEmail
          tollFree
          MAPBOX_ACCESS_TOKEN
        }
      }
      contactCallIcon: file(
        relativePath: {
          eq: "icons/contact-call-icon.svg"
        },
        sourceInstanceName: {
          eq: "assets"
        }
      ) {
        publicURL
      }
      contactEmailIcon: file(
        relativePath: {
          eq: "icons/contact-email-icon.svg"
        },
        sourceInstanceName: {
          eq: "assets"
        }
      ) {
        publicURL
      }
      contactLocationIcon: file(
        relativePath: {
          eq: "icons/contact-location-icon.svg"
        },
        sourceInstanceName: {
          eq: "assets"
        }
      ) {
        publicURL
      }
      allContentfulLayoutCopy(
        filter: {
          node_locale: { eq: "en" }
          title: { eq: "Contact Us > Header" }
        }
        limit: 1
      ) {
        edges {
          node {
            copy {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
        }
      }
    }
  `)
  const { MAPBOX_ACCESS_TOKEN } = data.site.siteMetadata
  const [bounds, setBounds] = React.useState({
    ...initialBounds,
    height: 300,
    width: 250
  })
  const viewport = new WebMercatorViewport({
    width: bounds.width,
    height: bounds.height
  })
  const { longitude, latitude, zoom } = viewport.fitBounds(LOCATIONS, {
    padding: 30,
  })
  const headerBody = path(
    [
      'allContentfulLayoutCopy',
      'edges',
      0,
      'node',
      'copy',
      'childMarkdownRemark',
      'rawMarkdownBody'
    ],
    data
  )
  console.log(data.contactLocationIcon.publicURL)
  return (
    <Layout>
      <Container maxWidth="md">
        <SEO title="Dental Services" />

        <Box my={3}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={LinkComponent} color="inherit" to="/en/">
              Home
            </Link>
            <Typography color="textPrimary">Contact Us</Typography>
          </Breadcrumbs>
        </Box>

        <HR />
        <Box my={3}>
          <Typography variant="h4" component="h1">
            Contact
          </Typography>

          <Box my={4}>
            <Typography>{headerBody}</Typography>
          </Box>
        </Box>
      </Container>

      <Wrapper>
        <Container maxWidth="md">
          <Box py={3}>
            <StyledPaper>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6}>
                  <MapWrapper>
                  <MapGL
                    style={{ width: '100%', height: '100%' }}
                    mapStyle='mapbox://styles/aridentaloffice/cjyr7two877fv1dplq3uv6g0j'
                    accessToken={MAPBOX_ACCESS_TOKEN}
                    latitude={latitude}
                    longitude={longitude}
                    zoom={12}
                    onViewportChange={(viewport) => {
                      // console.log('wtf')
                      // console.log('viewport', viewport)
                      // setBounds({ ...viewport }) 
                    }}
                  >
                    <Marker
                      longitude={-79.389550}
                      latitude={43.645550}
                    >
                      <MapMarker />
                    </Marker>
                    <Marker
                      longitude={-79.395510}
                      latitude={43.645320}
                    >
                      <MapMarker />
                    </Marker>
                    <Marker
                      longitude={-79.42090}
                      latitude={43.64364}
                    >
                      <MapMarker />
                    </Marker>
                  </MapGL>     
                  </MapWrapper>

                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Box width="100%" >
                    <List component="nav" aria-label="office locations">
                      <Link href={`https://maps.google.com/?q=46 John Street, Toronto, Ontario, Canada`}>
                          <ListItem button>
                            <ListItemAvatar>
                              <Avatar
                                alt={`Avatar`}
                                src={data.contactLocationIcon.publicURL}
                              />
                            </ListItemAvatar> 
                            <ListItemText primary="Ari Dental - John Street" secondary="46 John Street" />
                          </ListItem>
                        </Link>
                        <Link href={`https://maps.google.com/?q=451 King Street West, Toronto, Ontario, Canada`}>
                          <ListItem button>
                            <ListItemAvatar>
                              <Avatar
                                alt={`Avatar`}
                                src={data.contactLocationIcon.publicURL}
                              />
                            </ListItemAvatar> 
                            <ListItemText primary="Ari Dental - King West" secondary="451 King West, Unit 104" />
                          </ListItem>
                        </Link>
                        <Link href={`https://maps.google.com/?q=1051 Queen St West, Toronto, Ontario, Canada`}>
                          <ListItem button>
                            <ListItemAvatar>
                              <Avatar
                                alt={`Avatar`}
                                src={data.contactLocationIcon.publicURL}
                              />
                            </ListItemAvatar> 
                            <ListItemText primary="Ari Dental - Queen Street" secondary="1051 Queen St West, Unit 1" />
                          </ListItem>
                        </Link>
                      <ListItem button>
                      <ListItemAvatar>
                        <Avatar
                          alt={`Avatar`}
                          src={data.contactCallIcon.publicURL}
                        />
                      </ListItemAvatar>
                        <ListItemText primary="Phone" secondary="416.977.1414" />
                      </ListItem>
                      <ListItem button>
                      <ListItemAvatar>
                          <Avatar
                            alt={`Avatar`}
                            src={data.contactEmailIcon.publicURL}
                          />
                        </ListItemAvatar> 
                        <ListItemText primary="Email" secondary="info@ari.dental" />
                      </ListItem>
                    </List>
                  </Box>
                </Grid>
              </Grid>
            </StyledPaper>
          </Box>
        </Container>
      </Wrapper>
    </Layout>
  )
}